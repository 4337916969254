export const baseEnvironment = {
	version: 'V1.3.0',
	versionDev: 'V1.14.53',
	requireHttps: true,
	userImgPlaceholder: 'assets/img/user.png',
	clientId: '58hpo5bmbv5q7ibqufpntrbkd8',
	redirectUri: 'https://admin365pay-dev.bcr.gob.sv/home',
	logoutUri: 'https://admin365pay-admin-users-pool-dev.auth.us-east-1.amazoncognito.com/',
	issuer: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_A6cPkuBS7',
	poolId: 'us-east-1_A6cPkuBS7',
	env: 'DEV',
	urlServer: 'https://back-admin365pay-dev.bcr.gob.sv',
}
